import ReactQuill from "react-quill";

export function isHTML(str: string) {
  var doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}

export function getRawText(str: string) {
  if (!isHTML(str)) return str;
  var doc = new DOMParser().parseFromString(str, "text/html");
  const rawText = Array.from(doc.body.childNodes).map(node => {
    return node.textContent;
  });
  return rawText.join("");
}

export function compareRichText(prev: string, curr: string) {
  return getRawText(prev) === getRawText(curr);
}

export function currencyFormat(value: string) {
  return `$${decimalFormat(value)}`;
}

export function decimalFormat(value: string) {
  return numberFormat(`${Number(value).toFixed(2)}`);
}

export function numberFormat(value: string) {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function textOrRichText(value: string) {
  return isHTML(value) ? (
    <ReactQuill theme="bubble" value={value} readOnly={true} />
  ) : (
    value
  );
}

export default function TextStatic({
  value,
  formatter,
  notApplicable,
}: {
  value?: string;
  formatter?: (v: string) => JSX.Element | string;
  notApplicable?: boolean | null;
}): JSX.Element | null {
  if (notApplicable) {
    return <b>{notApplicable && "N/A"}</b>;
  }
  if (!value) return null;
  return (
    <p
      className="rating-txt avoid-page-break-inside"
      data-cy="field__text-static"
    >
      {formatter ? formatter(value) : value}
    </p>
  );
}
